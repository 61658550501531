import service from "@/api/service";
import { BookingGetRequest } from "@/api/analysis/booking/request";
import { BookingGetResponse } from "@/api/analysis/booking/response";

/**
 * 予約APIをコールします。
 *
 * @param getRequest 予約のリクエストボディ
 * @return ReservationPersonGetResponse
 */
export async function get(getRequest: BookingGetRequest) {
  const response = await service.post("/analysis-booking", getRequest);
  return response.data as BookingGetResponse;
}
