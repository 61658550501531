import { Component, Mixins, Watch } from "vue-property-decorator";
import AnalysisBase from "@/models/analysis/analysis-base";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import Flash, { ErrorAlert } from "@/store/common/flash";
import ShopGet from "@/store/shop/get";
import BookingChart from "@/views/analysis/BookingChart.vue";
import BookingGet from "@/store/analysis/booking/get";
import { BookingGetRequest } from "@/api/analysis/booking/request";
import Admin from "@/store/admin/admin";

export interface BookingInputOptions {
  startDate: string;
  endDate: string;
  viewType: number;
  categoryType: number;
  selectShop: { id: string; name: string } | null;
}

@Component({
  components: {
    UlContentHeader,
    UIDatePicker,
    BookingChart
  }
})
export default class AnalysisBooking extends Mixins(AnalysisBase) {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "予約";
  headingSub = "Reservation";
  breadCrumbs = [{ text: "予約", disabled: true }];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  viewTypes = [
    { label: "日別", value: 1 },
    { label: "月別", value: 2 },
    { label: "年別", value: 3 },
    { label: "時間別", value: 4 },
    { label: "コース", value: 5 },
    { label: "曜日別", value: 6 }
  ];

  tabItems = [{ title: "予約人数" }, { title: "予約件数" }];

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoadedShopData = false;
  isLoadedBookingData = false;

  // DatePickerの表示フラグ
  startDateModal = null;
  endDateModal = null;

  // 検索入力オプション
  inputOptions: BookingInputOptions = {
    startDate: this.initDates[0],
    endDate: this.initDates[1],
    viewType: 1,
    categoryType: 1,
    selectShop: null
  };

  totalCountOnDrillDowned = 0;
  displayType = 0; //0:予約人数, 1:予約件数, 2:円グラフ

  @Watch("activeTab")
  watchActiveTab(newTabIndex: number) {
    this.inputOptions.categoryType = newTabIndex + 1;
    this.totalCountOnDrillDowned = 0;
    this.displayType = newTabIndex;
  }

  /**
   * 総件数
   */
  get totalCount() {
    if (this.displayType == 1 || this.totalCountOnDrillDowned == 0) {
      return this.totalBookingCount;
    } else if (this.displayType == 2) {
      return this.totalCountOnDrillDowned;
    }

    return BookingGet.getTotalCount;
  }

  get totalBookingCount() {
    let bookingCount = 0;
    if (this.bookingData.datasets[0]) {
      this.bookingData.datasets[0].data.forEach(d => {
        bookingCount += Number(d)
      });
    }
    return bookingCount;
  }

  get totalCountSuffix() {
    const suffixes = ["人", "件"];
    return suffixes[this.activeTab];
  }

  get bookingData() {
    return BookingGet.getData;
  }

  get startDateForView() {
    return this.replaceHyphenToSlash(this.inputOptions.startDate);
  }
  set startDateForView(date) {
    this.inputOptions.startDate = this.replaceSlashToHyphen(date);
  }

  get endDateForView() {
    return this.replaceHyphenToSlash(this.inputOptions.endDate);
  }
  set endDateForView(date) {
    this.inputOptions.endDate = this.replaceSlashToHyphen(date);
  }

  get activeTab() {
    return this.$route.query.abk_tab ? Number(this.$route.query.abk_tab) : 0;
  }

  set activeTab(val) {
    let query = { ...this.$route.query };
    query.abk_tab = String(val);
    this.$router.replace({ query: query }).then();
  }

  get shopItems() {
    return ShopGet.getItems;
  }

  get initDates() {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDate = new Date(y, m, 1);
    const lastDate = new Date(y, m + 1, 0);
    return [this.stringFromDate(firstDate), this.stringFromDate(lastDate)];
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    if (this.editFocused || !this.isNeededActiveOnDashBoard) {
      return false;
    }

    await this.fetchShopList();
    this.watchActiveTab(this.activeTab);
    if (!Admin.isAdmin) {
      this.inputOptions.selectShop = ShopGet.getItems[0];
      await this.fetchRootChartData();
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await ShopGet.clearResponse();
    await BookingGet.clearResponse();
  }

  async fetchRootChartData() {
    this.isLoadedBookingData = false;
    await this.fetchBooking();
    this.totalCountOnDrillDowned = 0;
  }

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    this.isLoadedShopData = false;
    await ShopGet.get();
    if (!ShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedShopData = true;
    return ShopGet.isSuccess;
  }

  /**
   * グラフデータを取得する処理
   */
  async fetchBooking(): Promise<boolean> {
    this.isLoadedBookingData = false;
    await Flash.clear();
    const bookingGetRequest: BookingGetRequest = {
      startDate: this.startDateForView,
      endDate: this.endDateForView,
      viewType: this.inputOptions.viewType,
      categoryType: this.inputOptions.categoryType,
      searchShopId:
        this.inputOptions.selectShop != null
          ? Number(this.inputOptions.selectShop.id)
          : null
    };
    await BookingGet.get(bookingGetRequest);
    if (!BookingGet.isSuccess) {
      await Flash.setErrorNow({
        message: BookingGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedBookingData = true;
    return BookingGet.isSuccess;
  }

  updateTotalCountOnDrillDowned(value: number) {
    this.displayType = 2;
    this.totalCountOnDrillDowned = value;
  }
}
