import {
  Component,
  Emit,
  Mixins,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";
import UlBarChart from "@/models/analysis/ul-bar-chart";
import UlPieChart from "@/models/analysis/ul-pie-chart";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { BookingAttributeGetRequest } from "@/api/analysis/booking-attribute/request";
import AnalysisBase from "@/models/analysis/analysis-base";
import { ChartElement } from "@/models/analysis/analysis-base";
import { AnalysisGraphData } from "@/api/analysis/common/response";
import { BookingInputOptions } from "@/models/analysis/booking";
import BookingGet from "@/store/analysis/booking/get";
import BookingAttributeGet from "@/store/analysis/booking-attribute/get";
import * as BookingUserCSVGet from "@/api/analysis/booking-user-csv";
import { isSuccess } from "@/api/response";
import { BookingUserCSVGetRequest } from "@/api/analysis/booking-user-csv/request";

@Component({
  components: {
    UlBarChart,
    UlPieChart
  }
})
export default class AnalysisBookingChart extends Mixins(AnalysisBase) {
  @Prop() inputOptions!: BookingInputOptions;
  @Prop() data!: AnalysisGraphData;
  @Prop() tabIndex!: number;

  @Watch("totalCountOnDrillDowned")
  didUpdateTotalCount(value: number) {
    this.updateTotalCountOnDrillDowned(value);
  }

  @Emit()
  public updateTotalCountOnDrillDowned(value: number) {
    return value;
  }

  chart: Record<string, any> = {};

  // ローディングステータス
  isLoadedBookingAttribute = false;

  drillDownHierarchy = 0;
  totalCountOnDrillDowned = 0;

  get bookingChartData() {
    return this.getBarChartData(this.data);
  }

  get bookingChartOption() {
    return this.getBarChartOptions(
      this.data,
      false,
      false,
      this.drillDownBookingAttribute
    );
  }

  get bookingAttributeRankChartData() {
    return this.getPieChartData(BookingAttributeGet.getRankData);
  }

  get bookingAttributeRankChartOption() {
    return this.getPieChartOptions(
      BookingAttributeGet.getRankData,
      this.fetchBookingUserCSVByRank
    );
  }

  get bookingAttributeCustomerClassChartData() {
    return this.getPieChartData(BookingAttributeGet.getCustomerClassData);
  }

  get bookingAttributeCustomerClassChartOption() {
    return this.getPieChartOptions(
      BookingAttributeGet.getCustomerClassData,
      this.fetchBookingUserCSVByCustomerClass
    );
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await BookingAttributeGet.clearResponse();
  }

  async fetchBookingAttribute(
    inputOptions: BookingAttributeGetRequest
  ): Promise<boolean> {
    this.isLoadedBookingAttribute = false;
    await Flash.clear();
    await BookingAttributeGet.get(inputOptions);
    if (!BookingAttributeGet.isSuccess) {
      await Flash.setErrorNow({
        message: BookingAttributeGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedBookingAttribute = true;
    return BookingAttributeGet.isSuccess;
  }

  onClickBack() {
    this.totalCountOnDrillDowned = 0;
    this.drillDownHierarchy = 0;
  }

  async drillDownBookingAttribute(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0) ||
      this.inputOptions.selectShop == null ||
      this.isDashboard
    ) {
      return;
    }

    const element = this.chart.getElementAtEvent(event)[0] as ChartElement;
    if (event.ctrlKey || event.shiftKey) {
      await this.fetchBookingUserCSV(0, element._index);
      return;
    }

    if (this.tabIndex != 1) {
      return;
    }

    const bookingAttributeGetRequest: BookingAttributeGetRequest = {
      startDate: this.inputOptions.startDate,
      endDate: this.inputOptions.endDate,
      viewType: this.inputOptions.viewType,
      horizontalAxis: String(this.data.labelsData[element._index]),
      shopId: Number(this.inputOptions.selectShop.id)
    };

    await this.fetchBookingAttribute(bookingAttributeGetRequest);

    const data = this.data.datasets[element._datasetIndex].data;
    if (data != null) {
      this.totalCountOnDrillDowned = Number(data[element._index]);
    }
    this.drillDownHierarchy = 1;
  }

  async fetchBookingUserCSV(graphType: number, elementIndex: number) {
    const bookingUserCSVGetRequest: BookingUserCSVGetRequest = {
      searchShopId:
        this.inputOptions.selectShop != null
          ? Number(this.inputOptions.selectShop.id)
          : 0,
      startDate: this.inputOptions.startDate,
      endDate: this.inputOptions.endDate,
      viewType: this.inputOptions.viewType,
      categoryType: this.inputOptions.categoryType,
      graphType: graphType
    };

    bookingUserCSVGetRequest.horizontalAxis =
      graphType == 0
        ? BookingGet.getData.labelsData[elementIndex]
        : BookingAttributeGet.getGetRequest.horizontalAxis;

    if (graphType == 1) {
      bookingUserCSVGetRequest.attributeData = String(
        BookingAttributeGet.getRankData.labels[elementIndex]
      );
    } else if (graphType == 2) {
      bookingUserCSVGetRequest.attributeData = String(
        BookingAttributeGet.getCustomerClassData.labels[elementIndex]
      );
    }

    const bookingUserCSVGetResponse = await BookingUserCSVGet.get(
      bookingUserCSVGetRequest
    );

    if (
      bookingUserCSVGetResponse &&
      bookingUserCSVGetResponse.statusCd !== 200
    ) {
      await Flash.setErrorNow({
        message: bookingUserCSVGetResponse.message,
        showReloadButton: false
      } as ErrorAlert);
      return false;
    }

    this.saveCSV(
      bookingUserCSVGetResponse.results.csvString,
      bookingUserCSVGetResponse.results.fileName
    );
  }

  async fetchBookingUserCSVByRank(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchBookingUserCSVByAttribute(1, event, activeElements);
  }

  async fetchBookingUserCSVByCustomerClass(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchBookingUserCSVByAttribute(2, event, activeElements);
  }

  async fetchBookingUserCSVByAttribute(
    graphType: number,
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0)
    ) {
      return;
    }

    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      await this.fetchBookingUserCSV(graphType, element._index);
    }
  }

  chartRendered(chart: Record<string, any>) {
    this.chart = chart;
  }
}
